import React, { useState, useEffect, useContext } from "react";
import "./details.css";
import Contenedor from "../../Utils/Visuales/Contenedor/contenedor";
import Tabs from "../../Utils/Tabs/tabs";
import { TextField, withStyles, CircularProgress } from "@material-ui/core";
import EditButton from "../../Utils/Botones/EditButton";
import DatosGenerales from "./general/DatosGenerales";
import Competencia from "./competencia/Competencia";
import Filosofia from "./filosofia/Filosofia";
import Materiales from "./materiales/Materiales";
import Calendario from "./calendario/Calendario";
import Estrategias from "./estrategias/Estrategias";
import Evaluacion from "./evaluacion/Evaluacion";
import Plan from "./Plan/Plan";
import NewModal from "../../Utils/Visuales/NewModal/newModal";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Switch, Route, useHistory } from "react-router-dom";
import MateriaContext from "../../Utils/Providers/materia";
import ModalSaveChanges from "../../Utils/ModalSaveChanges";
import uuid from "uuid/v4";
import { useSnackbar } from "notistack";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "rgb(231, 36, 0)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgb(231, 36, 0)",
    },
    "& MuiInputBase-input": {
      padding: "8.5px 14px",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#1b1c20",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgb(231, 36, 0)",
      },
    },
  },
})(TextField);

export default function Details(props) {
  const context = useContext(MateriaContext);
  const [modalSyllabus, setModalSyllabus] = useState(false);
  const [tab, setTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [documentoBase, setDocumentoBase] = useState({ tipo: "", url: "" });
  const [changesMade, setChangesMade] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [jsonDocs, setJsonDocs] = useState([]);
  const [planeacionData, setPlaneacionData] = useState([]);
  const [inf, setInf] = useState(context.state.item);
  const [teacherKey, setTeacherKey] = useState(context.state.teacherKey);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [cargandoIFrame, setCargandoIframe] = useState(false);
  const [iteracion, setIteracion] = useState(0);
  const [keyIFrame, setKeyIFrame] = useState(false);
  const [urlCompleta, setUrlCompleta] = useState("");
  const [urlArchivoVisualizar, setUrlArchivoVisualizar] = useState("");
  const [enviado, setEnviado] = useState(true);

  const file_url =
    "https://uvmsyllabus.s3-us-west-2.amazonaws.com/Cocina_mediterranea_feb+2020_sin+ENE.docx";

  let history = useHistory();

  const namesTabs = [
    ["/details/planeacion", "Planeación", ""],
    // ['/details/criterios', "Criterios Evaluación","Criterios Evaluación"],
    // ['/details/generales', "Datos Generales","Generales"],
    // ['/details/filosofia', "Elem. Filosofía","Filosofia"],
    // ['/details/competencia', "Competencia","Competencia"],
    // ['/details/calendario', "Distribución Horaria y Crediticia","Calendario"],
    // ['/details/materiales', "Materiales","Materiales"],
    // ['/details/estrategias', "Estrategias, enseñanzas y aprendizaje","Estrategias Enseñanzas Aprendizaje"],
  ];

  useEffect(() => {
    console.log("change tab");
  }, [tab]);

  useEffect(() => {
    setEnviado(context.state.item.envio_planeacion_alumnos === "True");
  }, []);

  useEffect(() => {
    if (urlArchivoVisualizar) {
      const my_urlCompleta = encodeURIComponent(urlArchivoVisualizar);
      setUrlCompleta(
        `https://docs.google.com/gview?url=${my_urlCompleta}&embedded=true`
      );
      setTimeout(() => {
        setIteracion(1);
      }, 15000);
    }
  }, [urlArchivoVisualizar]);

  useEffect(() => {
    if (iteracion === 0) {
      return;
    }
    if (urlArchivoVisualizar && cargandoIFrame) {
      const my_urlCompleta = encodeURIComponent(urlArchivoVisualizar);
      setUrlCompleta(
        `https://docs.google.com/gview?url=${my_urlCompleta}&embedded=true`
      );
      enqueueSnackbar("Cargando archivo por favor espere", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      setKeyIFrame(keyIFrame + 1);
      setTimeout(() => {
        setIteracion(iteracion + 1);
      }, 15000);
    }
  }, [iteracion]);

  const handleOpenModal = () => {
    setModalSyllabus(true);
    setCargandoIframe(true);
    setUrlArchivoVisualizar(documentoBase.url);
    //setLoading(true);
  };

  function groupContent(content) {
    let newSessions = [];
    for (let i = 0; i < content.length; i++) {
      let unitIndex = newSessions.findIndex((elem) => {
        return content[i].unidad == elem.unit;
      });
      if (unitIndex != -1) {
        newSessions[unitIndex].content.push(content[i]);
      } else {
        newSessions.push({
          unit: content[i].unidad,
          content: [content[i]],
        });
      }
    }

    return newSessions;
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let response = await getRequest(
          `/detalle-syllabus?clave_materia=${inf.clave}&clave_docente=${teacherKey}&nivel=${inf.nivel}&crn=${inf.crn}`
        );
        let obtenido_generales = false;
        if (response.s === "OK") {
          let docs = response.d.documentos;
          let jsonDocs2 = {};
          for (let i = 0; i < docs.length; i++) {
            jsonDocs2[docs[i].tipo] = docs[i].url;
          }

          context.actions.setGenerales(null);
          context.actions.setFilosofia(null);
          context.actions.setCompetencia(null);
          context.actions.setCalendario(null);
          context.actions.setMateriales(null);
          context.actions.setEstrategias(null);
          context.actions.setCriterios(null);
          context.actions.setBibliografias(null);
          context.actions.setCriteriosAntiguos(null);

          response.d.planeacion.unidades = groupContent(
            response.d.planeacion.unidades
          );
          let obtenido_generales = await fetchGenerales(jsonDocs2["Generales"]);
          for (let i = 0; i < response.d.planeacion.sesiones.length; i++) {
            for (
              let j = 0;
              j < response.d.planeacion.sesiones[i].temas.length;
              j++
            ) {
              response.d.planeacion.sesiones[i].temas[j].idOriginal =
                response.d.planeacion.sesiones[i].temas[j].id;
              response.d.planeacion.sesiones[i].temas[j].id = uuid();
            }
          }

          context.actions.setPlaneacionAntigua(response.d.planeacion);
          setDocumentoBase(response.d.documento_base);
          setJsonDocs(jsonDocs2);
          setLoading(false);

          if (
            obtenido_generales &&
            history.location.pathname.includes("details")
          ) {
            history.push("/details/planeacion");
          }
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else {
          setLoading(false);
          enqueueSnackbar(response.m, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      } catch (error) {
        enqueueSnackbar("Error obteniendo detalle", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setLoading(true);
        console.log("error", error);
      }
    })();
  }, []);

  async function fetchGenerales(url) {
    let response = await fetch(url);
    let data = await response.json();
    let json = [
      {
        name: "Vertical",
        content: data.vertical,
      },
      {
        name: "Licenciatura",
        content: data.licenciatura,
      },
      {
        name: "Academia",
        content: data.academia,
      },
      {
        name: "Asignatura",
        content: data.asignatura,
      },
      {
        name: "Clave de la materia sep/banner",
        content: data.clave_materia,
      },
      {
        name: "Asignatura Antecedente",
        content: data.asignatura_antecedente,
      },
      {
        name: "Modalidad",
        content: data.modalidad,
      },
      {
        name: "Ciclo",
        content: data.ciclo,
      },
      {
        name: "Nombre del profesor",
        content: data.nombre_profesor,
      },
      {
        name: "Correo Electrónico Institucional",
        content: data.correo_institucional,
      },
    ];
    context.actions.setGeneralesCompletos(data);
    context.actions.setGenerales(json);
    return true;
  }

  function regresar() {
    if (context.state.changes) {
      setConfirmModal(true);
      return;
    }
    history.push("/dashboard");
  }
  function noSave() {
    history.push("/dashboard");
    setConfirmModal(false);
  }

  useEffect(() => {
    setTimeout(() => {
      if (cargandoIFrame && modalSyllabus) {
        setKeyIFrame(keyIFrame + 1);
      }
    }, 10000);
  }, [modalSyllabus, cargandoIFrame]);

  async function save() {
    context.actions.setPlaneacionAntigua(
      JSON.parse(JSON.stringify(context.state.planeacion))
    );

    let sesiones2 = JSON.parse(
      JSON.stringify(context.state.planeacion.sesiones)
    );

    let sesionesArray = [];

    for (let i = 0; i < sesiones2.length; i++) {
      let temasArray = [];
      for (let j = 0; j < sesiones2[i].temas.length; j++) {
        if (sesiones2[i].temas[j].tipo === "unidad") {
          temasArray.push(sesiones2[i].temas[j].idOriginal);
        } else {
          temasArray.push(sesiones2[i].temas[j].id);
        }
      }
      sesionesArray.push({
        id: sesiones2[i].id,
        temas: temasArray,
      });
    }

    let json = {
      clave_materia: context.state.item.clave,
      clave_docente: context.state.teacherKey,
      sesiones: sesionesArray,
    };
    let response = await postRequest("/editar-planeacion", json);
    if (response.s === "OK") {
      enqueueSnackbar(response.m, {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      setLoading(false);
    } else {
      enqueueSnackbar(response.m, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
    history.push("/dashboard");
    setConfirmModal(false);
  }

  return (
    <Contenedor title='Details'>
      <div className='container-inputs'>
        <EditButton onClick={regresar} variant='container'>
          <div className='header-btn'>
            <ArrowBackIcon />
            <span>Regresar</span>
          </div>
        </EditButton>
        <CssTextField
          id='outlined-read-only-input'
          label='Código materia'
          className='input-codigo-materia'
          defaultValue={inf.clave}
          InputProps={{
            readOnly: true,
          }}
          variant='outlined'
          size='small'
        />

        <CssTextField
          id='outlined-read-only-input'
          label='Materia'
          fullWidth
          defaultValue={inf.nombre_materia}
          InputProps={{
            readOnly: true,
          }}
          variant='outlined'
          size='small'
        />
        <EditButton variant='contained' onClick={handleOpenModal}>
          Descargar {documentoBase.tipo}
        </EditButton>
      </div>
      <Tabs
        namesTabs={namesTabs}
        changes={changesMade}
        separete={2}
        loading={loading}
      >
        <Switch>
          <Route path='/details/planeacion'>
            <Plan
              enviado={enviado}
              url={jsonDocs["Bibliografia"]}
              data={planeacionData}
              urlCriterios={jsonDocs["Criterios Evaluación"]}
            />
          </Route>
          <Route path='/details/criterios'>
            <Evaluacion
              enviado={enviado}
              url={jsonDocs["Criterios Evaluación"]}
            />
          </Route>

          <Route path='/details/generales'>
            <DatosGenerales enviado={enviado} url={jsonDocs["Generales"]} />
          </Route>

          <Route path='/details/filosofia'>
            <Filosofia enviado={enviado} url={jsonDocs["Filosofia"]} />
          </Route>

          <Route path='/details/competencia'>
            <Competencia enviado={enviado} url={jsonDocs["Competencia"]} />
          </Route>

          <Route path='/details/calendario'>
            <Calendario enviado={enviado} url={jsonDocs["Calendario"]} />
          </Route>

          <Route path='/details/materiales'>
            <Materiales enviado={enviado} url={jsonDocs["Materiales"]} />
          </Route>

          <Route path='/details/estrategias'>
            <Estrategias
              enviado={enviado}
              url={jsonDocs["Estrategias Enseñanzas Aprendizaje"]}
            />
          </Route>

          <Route path='/details'>
            <div style={{ textAlign: "center", marginTop: 20 }}>
              <CircularProgress size={50} />
            </div>
          </Route>
        </Switch>
      </Tabs>
      <NewModal
        title='Descarga de syllabus'
        open={modalSyllabus}
        handleClose={() => {
          setKeyIFrame(false);
          setModalSyllabus(false);
          setKeyIFrame(0);
          setUrlCompleta("");
          setUrlArchivoVisualizar("");
          setIteracion(0);
          setCargandoIframe(false);
        }}
        syllabus
        onDownload={documentoBase.url}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "65vh",
            overflow: "none",
            position: "relative",
          }}
        >
          <div style={{ width: "100%" }}>
            {cargandoIFrame && (
              <div
                style={{
                  textAlign: "center",
                  marginTop: 20,
                  position: "absolute",
                  left: "calc(50% - 25px)",
                }}
              >
                <CircularProgress size={50} />
              </div>
            )}
            {
              <iframe
                loading='lazy'
                key={"12312" + keyIFrame}
                id='reload-me'
                width='100%'
                height='100%'
                frameborder='0'
                src={urlCompleta}
                onLoad={() => {
                  setCargandoIframe(false);
                }}
              ></iframe>
            }
          </div>
        </div>
      </NewModal>
      <ModalSaveChanges
        onlyClose={() => {
          setConfirmModal(false);
        }}
        confirmModal={confirmModal}
        noSave={noSave}
        save={save}
        loading={loading}
      />
    </Contenedor>
  );
}
