/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { Grid, CircularProgress } from "@material-ui/core";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import WarningIcon from "@material-ui/icons/Warning";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import GetAppIcon from "@material-ui/icons/GetApp";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import SchoolIcon from "@material-ui/icons/School";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import { useSnackbar } from "notistack";
import Select from "react-select";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement,
  Filler,
  SubTitle,
} from "chart.js";

import Contenedor from "../../Utils/Visuales/Contenedor/contenedor";
import Tabs from "../../Utils/Tabs/tabs";
import Indicators from "../../Utils/Indicators/indicators";
import DataTable from "../../Utils/DataTables/dataTables";
import { BlackButton } from "../../Utils/Botones";
import NewModal from "../../Utils/Visuales/NewModal/newModal";
import InfoTable from "../../Utils/InfoTable/infoTable";
import {
  getRequest,
  postRequest,
  URL_EVAL,
} from "../../Utils/Funciones/requester";
import MateriaContext from "../../Utils/Providers/materia";
import IndicadoresContex from "../../Utils/Providers/indicadores";

import "./dashboard.css";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  SubTitle,
  PointElement,
  LineElement,
  Filler
);

export default function Dashboard() {
  const headers = [
    "Clave",
    "Nombre Materia",
    "Fecha Inicio",
    "Fecha Fin",
    "Avance",
    "Acción",
  ];

  const [ciclo, setCiclo] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [modalSesion, setModalSesion] = useState(false);
  const [modalEnvio, setModalEnvio] = useState(false);
  const [modalEnviando, setModalEnviando] = useState(false);
  const [modalConfirmacionEnvio, setModalConfirmacionEnvio] = useState(false);
  const [modalCargaDocumento, setModalCargaDocumento] = useState(false);
  const [levelGroup, setLevelGroup] = useState([]);
  const [namesTabs, setNamesTabs] = useState([[]]);
  const [clave, setClave] = useState("");
  const [estadoEnvio, setEstadoEnvio] = useState("previo");
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();
  const context = useContext(MateriaContext);
  const contextIndicadores = useContext(IndicadoresContex);
  const [horarioModal] = useState({
    clave: "",
    crn: "",
    fecha_fin: "",
    fecha_inicio: "",
    nivel: "",
    nombre_materia: "",
    periodo: "",
    profesor: "",
    sesiones: "",
    calendario: "",
    nss: "",
  });
  const [materiaSeleccionada, setMateriaSeleccionada] = useState(null);
  const [fileName, setFileName] = useState("");
  const [loadingFile, setLoadingFile] = useState(false);
  const [modalDocumentos, setModalDocumentos] = useState(false);
  const [modalDocumentosLoading, setModalDocumentosLoading] = useState(false);
  const [documentos, setDocumentos] = useState([]);
  const [modalEvidenciasIsOpen, setModalEvidenciasIsOpen] = useState(false);
  const [modalRolDocenteIsLoading, setModalRolDocenteIsLoading] =
    useState(false);
  const [evidencias, setEvidencias] = useState([]);
  const [nombreMateria, setNombreMateria] = useState("");
  const [evidenciasTableData, setEvidenciasTableData] = useState([]);
  const [modalADNLinceIsOpen, setModalADNLinceIsOpen] = useState(false);
  const [modalADNLinceIsLoading, setModalADNLinceIsLoading] = useState(false);
  const [reporteADNLince, setReporteADNLince] = useState({});
  const [PDFURL, setPDFURL] = useState("");
  const [modalADNLinceConfirmacionIsOpen, setModalADNLinceConfirmacionIsOpen] =
    useState(false);
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const [contextMenuMateriaInfo, setContextMenuMateriaInfo] = useState({});
  const [
    modalADNLinceConfirmacionIsLoading,
    setModalADNLinceConfirmacionIsLoading,
  ] = useState(false);
  const [uploadType, setUploadType] = useState("");
  const [fileInfo, setFileInfo] = useState({});
  const [resultadoEvidencias, setResultadoEvidencias] = useState({});

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        event.target.id !== "context-menu-button" &&
        event.target.id !== "context-menu-delete-document-button"
      ) {
        setContextMenuOpen(false);
      }
    };
    if (contextMenuOpen) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  const indicators = [
    {
      name: "Total Asignaciones",
      number: contextIndicadores.state.data
        ? contextIndicadores.state.data.length
        : 0,
      color: "#ffbc58",
      icon: LibraryBooksIcon,
    },
    {
      name: "Total asignaciones con programación completa",
      number: contextIndicadores.state.completados
        ? contextIndicadores.state.completados.length
        : 0,
      color: "#ff8084",
      icon: DoneOutlineIcon,
    },
    {
      name: "Total de asignaciones incompletas",
      number: contextIndicadores.state.completados
        ? contextIndicadores.state.data.length -
          contextIndicadores.state.completados.length
        : 0,
      color: "#00a65a",
      icon: WarningIcon,
    },
    {
      name: "Avance",
      number: contextIndicadores.state.completados
        ? (
            (contextIndicadores.state.completados.length /
              contextIndicadores.state.data.length) *
            100
          ).toFixed(1) + "%"
        : "0%",
      color: "#13c9ca",
      icon: TrendingUpIcon,
    },
  ];

  const headersHorario = [
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
  ];

  function groupByLevel(data) {
    let groups = [];

    for (let i = 0; i < data.length; i++) {
      let index = groups.findIndex((elem) => elem.tipo === data[i].tipo);

      if (index !== -1) {
        groups[index].materias.push(data[i]);
      } else {
        groups.push({
          tipo: data[i].tipo,
          materias: [data[i]],
        });
      }
    }

    return groups;
  }

  function getButtonContent(tipo, url) {
    switch (tipo) {
      case "syllabus":
        return (
          <>
            <ImportContactsIcon /> Generar Planeación
          </>
        );
      case "cargar_documento":
        if (url) {
          return (
            <>
              <PermMediaIcon /> Descargar Documento
            </>
          );
        } else {
          return (
            <>
              <PermMediaIcon /> Cargar Documento
            </>
          );
        }
      case "descargar_documento":
        return (
          <>
            <PermMediaIcon /> Descargar Documento
          </>
        );
      default:
        return (
          <>
            <ImportContactsIcon /> Syllabus
          </>
        );
    }
  }

  async function handleClickDocumentos(id) {
    setModalDocumentos(true);
    setModalDocumentosLoading(true);
    try {
      let response = await getRequest(`/archivo/materia?id=${id}`);
      if (response.s === "OK") {
        if (response.d.length > 0) {
          let docs = [];
          for (let i = 0; i < response.d.length; i++) {
            docs.push([
              response.d[i]["nombre"],
              <a
                href={response.d[i]["url"]}
                target='_blank'
                rel='noopener noreferrer'
                download
              >
                <button className='black-btn'>Descargar</button>
              </a>,
            ]);
          }
          setDocumentos(docs);
        }
        setModalDocumentosLoading(false);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setModalDocumentosLoading(false);
      }
    } catch (error) {
      enqueueSnackbar("Error obteniendo lista de dashboard", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      setModalDocumentosLoading(false);
      console.log(error);
    }
  }

  const handleDeleteEvidencia = async (
    id_person,
    id_teacher_assignment,
    id_evidencia_aprendizaje
  ) => {
    setModalRolDocenteIsLoading(true);
    try {
      const res = await postRequest("/evidencias/estatus", {
        id_person,
        id_teacher_assignment,
        id_evidencia_aprendizaje,
        estatus: "Eliminado",
      });
      if (res.s === "OK") {
        enqueueSnackbar(res.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        handleClickEvidenciasAprendizaje(id_person, id_teacher_assignment);
      } else {
        enqueueSnackbar(res.m, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      enqueueSnackbar("Error al eliminar evidencia", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      console.error(error);
    } finally {
      setModalRolDocenteIsLoading(false);
    }
  };

  const handleClickEvidenciasAprendizaje = async (
    id_person,
    id_teacher_assignment
  ) => {
    setModalEvidenciasIsOpen(true);
    setModalRolDocenteIsLoading(true);
    try {
      let response = await getRequest(
        `/evidencias?id_person=${id_person}&id_teacher_assignment=${id_teacher_assignment}`
      );
      if (response.s === "OK") {
        setEvidencias(response.d);
        setResultadoEvidencias(response.d.resultado);
        const evaluado = response.d.actividades.every(
          (evidencia) => evidencia.status === "Evaluado"
        );
        setEvidenciasTableData(
          response.d.actividades.map((item) => [
            item.nombre,
            <>
              <BlackButton
                disabled={
                  (item?.url !== null && item?.url.length > 0) || evaluado
                }
                onClick={() => {
                  console.log(contextMenuMateriaInfo);
                  setModalCargaDocumento(true);
                  setMateriaSeleccionada(contextMenuMateriaInfo);
                  setUploadType("Evidencia de Aprendizaje");
                  setFileInfo({
                    id_evidencia: item.id,
                    id_person,
                    id_teacher_assignment,
                  });
                }}
              >
                Examinar
              </BlackButton>
              <BlackButton
                disabled={
                  item?.status?.toLowerCase() === "pendiente" ||
                  item?.url === null ||
                  evaluado
                }
                onClick={() =>
                  handleDeleteEvidencia(
                    id_person,
                    id_teacher_assignment,
                    item.id
                  )
                }
              >
                Eliminar
              </BlackButton>
            </>,
            item.status,
            item.comentario,
          ])
        );
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      enqueueSnackbar("Error obteniendo informacion sobre la evidencia", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      console.error(error);
    } finally {
      setModalRolDocenteIsLoading(false);
    }
  };

  const handleOpenModalADNLince = async (id_person, id_teacher_assignment) => {
    setModalADNLinceIsOpen(true);
    setModalADNLinceIsLoading(true);
    try {
      const response = await getRequest(
        `/reporte_adn_lince?id_person=${id_person}&id_teacher_assignment=${id_teacher_assignment}`
      );
      setReporteADNLince(response.d);
      setPDFURL(response.d.url_pdf);
    } catch (error) {
      console.log("Error obteniendo informacion de ADN Lince: ", error);
      enqueueSnackbar("Error obteniendo informacion de ADN Lince", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      setModalADNLinceIsOpen(false);
    } finally {
      setModalADNLinceIsLoading(false);
    }
  };

  function getMaterias(materias) {
    let data = [];

    materias.forEach((item) => {
      data.push([
        item.clave,
        item.nombre_materia,
        item.fecha_inicio,
        item.fecha_fin,
        Math.round(item.avance) + "%",
        <div className='buttons-container'>
          <div className='button-subcontainer'>
            <BlackButton
              id='context-menu-button'
              onClick={(ev) => {
                const xPosition =
                  window.innerWidth - ev.clientX < 250
                    ? ev.clientX - 250
                    : ev.clientX;
                const cursorCoordinates = {
                  x: xPosition,
                  y: ev.clientY,
                };
                setContextMenuOpen(true);
                setContextMenuPosition(cursorCoordinates);
                setContextMenuMateriaInfo(item);
              }}
            >
              Opciones
            </BlackButton>
          </div>
        </div>,
      ]);
    });
    return data;
  }

  useEffect(() => {
    getDashboard(ciclo.value);
  }, [ciclo]);

  async function getDashboard(ciclo) {
    setTableLoading(true);
    try {
      let response = await getRequest(
        "/dashboard" + (ciclo ? `?ciclo=${ciclo}` : "")
      );
      if (response.s === "OK") {
        let data = groupByLevel(response.d);
        setLevelGroup(data);
        calcularIndicadores(response.d);
        setNamesTabs(
          data.map((row) => [`/dashboard/${row.tipo}`, row.tipo, row.materias])
        );
        history.push(`/dashboard/${data[0].tipo}`);
        setTableLoading(false);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setTableLoading(false);
      }
    } catch (error) {
      enqueueSnackbar("Error obteniendo lista de dashboard", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      setTableLoading(false);
      console.log(error);
    }
  }

  function calcularIndicadores(completa) {
    let completas = completa.filter((reg) => reg.avance === 100);
    contextIndicadores.actions.setData(completa);
    contextIndicadores.actions.setCompletados(completas);
  }

  useEffect(() => {
    contextIndicadores.actions.setLastPage(0);
  }, [contextIndicadores.actions.setTab]);

  const handleSubmitEnvio = async () => {
    setModalConfirmacionEnvio(false);
    setModalEnviando(true);

    try {
      setEstadoEnvio("Buscando datos de evaluación");
      const response = await postRequest("/enviaralumnos", {
        id: clave,
        accion: "data",
      });
      if (response.s === "OK") {
        setEstadoEnvio("Enviando evaluación");
        const responseEnviado = await sendToWSEvaluaciones(
          "/syllabus/planeacion/insertar",
          response.d
        );
        if (responseEnviado.s === "OK") {
          const responseExito = await postRequest("/enviaralumnos", {
            id: clave,
            accion: "enviado",
          });
          if (responseExito.s === "OK") {
            setModalEnviando(false);
            getDashboard();
            enqueueSnackbar(responseExito.m, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
          } else {
            enqueueSnackbar(responseExito.m, {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
          }
        } else {
          enqueueSnackbar(responseEnviado.m, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
      setModalEnviando(false);
    } catch (error) {
      setModalEnviando(false);
      console.error(error);
    }
  };

  async function sendToWSEvaluaciones(ws, json) {
    let token = localStorage.getItem("token_syllabus");
    let response = await fetch(URL_EVAL + ws, {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        tk: token,
      },
      body: JSON.stringify(json),
    });
    let data = await response.json();
    return data;
  }

  const uploadFile = async (ev) => {
    const file = document.getElementById("file");
    if (file.files.length <= 0) {
      enqueueSnackbar("Debe seleccionar un archivo", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }
    if (
      uploadType === "Evidencia de Aprendizaje" &&
      file.files[0].type !== "application/pdf"
    ) {
      enqueueSnackbar("El documento debe estar en formato  PDF", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }
    setFileName(file.files[0].name);
    if (file.files.length <= 0) {
      enqueueSnackbar("Debe seleccionar un archivo", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return;
    }
    try {
      setLoadingFile(true);
      const bodyBase = {
        docente: materiaSeleccionada.nss,
        curso: materiaSeleccionada.clave,
        tipo: "planeacion",
        archivo: file.files[0].name,
      };
      const bodyEvidencia = {
        ...bodyBase,
        tipo: uploadType,
        id_evidencia: fileInfo.id_evidencia,
        id_person: fileInfo.id_person,
        id_teacher_assignment: fileInfo.id_teacher_assignment,
      };
      const body =
        uploadType === "Evidencia de Aprendizaje" ? bodyEvidencia : bodyBase;
      const response = await postRequest("/archivo/presigned_url", body);
      if (response.s === "OK") {
        let formData = new FormData();
        formData.append("file", file.files[0]);
        let token = localStorage.getItem("token_syllabus");
        fetch(response.d.url_presigned, {
          mode: "cors",
          method: "PUT",
          headers: {
            tk: token,
          },
          body: formData,
        }).then(async () => {
          const bodyBase = {
            curso: materiaSeleccionada.clave,
            id: materiaSeleccionada.id,
            url: response.d.url,
          };
          const bodyEvidencia = {
            ...bodyBase,
            tipo: uploadType,
            id_evidencia: fileInfo.id_evidencia,
            id_person: fileInfo.id_person,
            id_teacher_assignment: fileInfo.id_teacher_assignment,
          };
          const body =
            uploadType === "Evidencia de Aprendizaje"
              ? bodyEvidencia
              : bodyBase;
          const responseUpload = await postRequest(
            "/archivo/subir_archivo",
            body
          );
          if (responseUpload.s === "OK") {
            enqueueSnackbar("Archivo subido con éxito", {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            getDashboard();
            if (uploadType === "Evidencia de Aprendizaje") {
              handleClickEvidenciasAprendizaje(
                fileInfo.id_person,
                fileInfo.id_teacher_assignment
              );
            }
            setModalCargaDocumento(false);
            setLoadingFile(false);
          } else {
            setLoadingFile(false);
            enqueueSnackbar(responseUpload.m, {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
          }
        });
      } else {
        setLoadingFile(false);
        enqueueSnackbar(response.msg, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      console.error(error);
      setLoadingFile(false);
      enqueueSnackbar("Error al subir archivo", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  const handleSubmitADNLince = async () => {
    setModalADNLinceConfirmacionIsLoading(true);
    try {
      const res = await postRequest("/firma_adn_lince", {
        id_person: contextMenuMateriaInfo.id_person,
        id_teacher_assignment: contextMenuMateriaInfo.id,
      });
      if (res.s === "OK") {
        enqueueSnackbar(res.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        setModalADNLinceConfirmacionIsOpen(false);
        setModalADNLinceIsOpen(false);
        getDashboard();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error al firmar el documento", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } finally {
      setModalADNLinceConfirmacionIsLoading(false);
    }
  };

  const evidenciasTableHeaders = ["Nombre", "Acción", "Estatus", "Comentario"];

  const handleDeleteDocument = () => {
    const newLevelGroup = JSON.parse(JSON.stringify(levelGroup));

    const level = levelGroup.find(
      (level) => level.tipo === contextMenuMateriaInfo.tipo
    );
    const index = level.materias.findIndex(
      (level) => level.id === contextMenuMateriaInfo.id
    );
    level.materias[index].url_planeacion = "";
    newLevelGroup.find(
      (level) => level.tipo === contextMenuMateriaInfo.tipo
    ).materias = level.materias;
    setLevelGroup(newLevelGroup);
  };

  const handleClickFirmar = async (id) => {
    try {
      const res = await postRequest(`/evaluacion/firma/${id}`);
      if (res.s === "OK") {
        enqueueSnackbar(res.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
        getDashboard();
      } else {
        enqueueSnackbar(res.m, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error al firmar el documento", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  return (
    <Contenedor title='Dashboards'>
      {tableLoading ? (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <CircularProgress size={50} />
        </div>
      ) : (
        <>
          <Indicators indicatorsInf={indicators} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "25px 0",
            }}
          >
            <label htmlFor='select-ciclo'>Ciclo: &nbsp;</label>
            <Select
              styles={{
                control: (provided) => ({
                  ...provided,
                  minWidth: "120px",
                }),
                menu: (provided) => ({
                  ...provided,
                  minWidth: "120px",
                  zIndex: "90",
                }),
              }}
              placeholder=''
              options={[
                { value: "202155", label: "202155" },
                { value: "202180", label: "202180" },
                { value: "202211", label: "202211" },
              ]}
              value={ciclo}
              onChange={(value) => setCiclo(value)}
            />
          </div>
          <Tabs
            namesTabs={namesTabs}
            getMaterias={getMaterias}
            levelGroup={levelGroup}
            selectedTab={contextIndicadores.state.tab}
            setActiveTab={contextIndicadores.actions.setTab}
          >
            <Switch>
              {levelGroup.map((row) => (
                <Route key={row.tipo} path={`/dashboard/${row.tipo}`}>
                  <DataTable
                    headers={headers}
                    data={getMaterias(row.materias)}
                    loading={tableLoading}
                    paginate
                    lastPage={contextIndicadores.state.lastPage}
                    setLastPage={contextIndicadores.actions.setLastPage}
                  />
                </Route>
              ))}
            </Switch>
          </Tabs>
        </>
      )}
      <NewModal
        title='Horario Asignación'
        open={modalSesion}
        handleClose={() => setModalSesion(false)}
        hideSubmit
        cerrarBtn
      >
        <div className='detalle-materia-form'>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <label>Periodo (*)</label>
              <input
                id='nuevo-periodo'
                value={horarioModal.periodo}
                placeholder='Ingresa Periodo'
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <label>CRN (*)</label>
              <input
                id='nuevo-crn'
                value={horarioModal.crn}
                placeholder='Ingresa CRN'
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <label>Nivel (*)</label>
              <input
                id='nuevo-nivel'
                value={horarioModal.nivel}
                placeholder='Ingresa Nivel'
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <label>Código (*)</label>
              <input
                id='nuevo-codigo'
                value={horarioModal.clave}
                placeholder='Ingresa Código'
                disabled
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <label>Materia (*)</label>
              <input
                id='nuevo-materia'
                value={horarioModal.nombre_materia}
                placeholder='Ingresa Materia'
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <label>Fecha inicio</label>
              <input
                id='nuevo-fini'
                value={horarioModal.fecha_inicio}
                placeholder='Ingresa fecha inicio'
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <label>Fecha fin (*)</label>
              <input
                id='nuevo-ffin'
                value={horarioModal.fecha_fin}
                placeholder='Ingresa fecha fin'
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <label id='label-rol'>Sesiones (*)</label>
              <input
                id='nuevo-sesion'
                value={horarioModal.sesiones}
                placeholder='Ingresa sesión'
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <label>Clave</label>
              <input
                id='nuevo-clave'
                value={horarioModal.nss}
                placeholder='Ingresa Clave'
                disabled
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <label>Profesor (*)</label>
              <input
                id='nuevo-profesor'
                value={horarioModal.profesor}
                placeholder='Ingresa Profesor'
                disabled
              />
            </Grid>
          </Grid>
        </div>
        <div className='container-horario'>
          <InfoTable
            headers={headersHorario}
            data={horarioModal.calendario}
          ></InfoTable>
        </div>
      </NewModal>
      <NewModal
        title='Enviar planeación a Alumnos'
        open={modalEnvio}
        handleClose={() => setModalEnvio(false)}
        handleSubmit={() => {
          setModalEnvio(false);
          setModalConfirmacionEnvio(true);
        }}
      >
        <div className='modal-envio'>
          <p className='modal-envio-content-title'>
            ¿Seguro que desea enviar esta planeación de Alumnos?
          </p>
          <p className='modal-envio-content-text'>
            Al realizar esta operación ya no podrá ser cancelada o modificada
          </p>
        </div>
      </NewModal>
      <NewModal
        title='Confirmación de Envío de Planeación'
        open={modalConfirmacionEnvio}
        handleClose={() => setModalConfirmacionEnvio(false)}
        handleSubmit={handleSubmitEnvio}
        continue
      >
        <div className='modal-envio-confirmacion'>
          <p className='modal-envio-confirmacion-content-title'>
            Recuerda que esta acción sólo puede ser <br /> ejecutada una única
            vez
          </p>
          <p className='modal-envio-confirmacion-content-text'>
            ¿Deseas continuar con el envío?
          </p>
        </div>
      </NewModal>
      <NewModal title='Enviando Planeación' open={modalEnviando} hideFooter>
        <div className='modal-enviando'>
          <CircularProgress size={80} />
          <p className='modal-enviando-content-text'>{estadoEnvio}</p>
        </div>
      </NewModal>
      <NewModal
        title='Cargar Documento'
        open={modalCargaDocumento}
        handleClose={() => setModalCargaDocumento(false)}
        handleSubmit={uploadFile}
        hideFooter={loadingFile}
      >
        {loadingFile ? (
          <div className='modal-envio'>
            <p className='modal-envio-content-title'>
              Cargando archivo: {fileName}
              <CircularProgress size={30} style={{ marginLeft: "30px" }} />
            </p>
          </div>
        ) : (
          <div className='modal-envio'>
            <p className='modal-envio-content-title'>
              Seleccione el documento que desea cargar
            </p>
            <input type='file' id='file' />
          </div>
        )}
      </NewModal>
      <NewModal
        title='Documentos'
        open={modalDocumentos}
        handleClose={() => setModalDocumentos(false)}
        hideSubmit
      >
        <div style={{ padding: "40px 70px", height: "400px" }}>
          <DataTable
            headers={["Nombre", "Accion"]}
            data={documentos}
            loading={modalDocumentosLoading}
            paginate
            lastPage={contextIndicadores.state.lastPage}
            setLastPage={contextIndicadores.actions.setLastPage}
          />
        </div>
      </NewModal>
      <NewModal
        title='Evidencias Aprendizaje'
        open={modalEvidenciasIsOpen}
        loading={modalRolDocenteIsLoading}
        handleClose={() => setModalEvidenciasIsOpen(false)}
        handleSubmit={() => setModalEvidenciasIsOpen(false)}
        maxWidth='1000px'
      >
        <div style={{ padding: "18px" }}>
          <div
            style={{ marginBottom: "24px" }}
            className='detalle-materia-form'
          >
            <Grid container spacing={1}>
              <Grid item md={3}>
                <label>Calendario</label>
                <input
                  id='nuevo-calendario'
                  value={evidencias.nombre_calendario}
                  disabled
                />
              </Grid>
              <Grid item md={3}>
                <label>Periodo Carga de Evidencias</label>
                <input
                  id='nuevo-periodo-carga'
                  value={
                    evidencias.fecha_inicio_carga +
                    " / " +
                    evidencias.fecha_fin_carga
                  }
                  disabled
                />
              </Grid>
              <Grid item md={3}>
                <label>Fecha Evaluación</label>
                <input
                  id='nuevo-feva'
                  value={evidencias.fecha_evaluacion}
                  disabled
                />
              </Grid>
              <Grid item md={3}>
                <label>Materia</label>
                <input
                  id='nuevo-materia'
                  value={nombreMateria}
                  placeholder='Ingresa materia'
                  disabled
                />
              </Grid>
            </Grid>
          </div>
          <div>
            <DataTable
              headers={evidenciasTableHeaders}
              data={evidenciasTableData}
              loading={false}
            />
          </div>
          <div
            style={{
              display: evidenciasTableData.every(
                (evidencia) => evidencia[2] === "Evaluado"
              )
                ? "flex"
                : "none",
              justifyContent: "space-between",
              marginTop: "40px",
            }}
          >
            <div style={{ marginRight: "24px" }}>
              <label
                style={{
                  marginRight: "14px",
                  fontSize: "13px",
                }}
              >
                Resultado de Evaluación:
              </label>
              <input
                disabled
                type='text'
                style={{
                  padding: "4px",
                  textAlign: "center",
                  maxWidth: "95px",
                }}
                value={resultadoEvidencias.total_puntaje}
              />
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                marginRight: "24px",
              }}
            >
              <label
                style={{
                  marginTop: "6px",
                  marginRight: "14px",
                  fontSize: "13px",
                }}
              >
                Comentario:
              </label>
              <textarea
                disabled
                type='text'
                style={{ flex: 1, padding: "4px", resize: "vertical" }}
                placeholder='comentarios del evaluador'
                value={resultadoEvidencias.comentario_global}
              />
            </div>
            <div>
              <button
                style={{ padding: "4px 26px" }}
                onClick={() =>
                  handleClickFirmar(resultadoEvidencias.id_teacher_assignment)
                }
              >
                Firmar
              </button>
            </div>
          </div>
        </div>
      </NewModal>
      <NewModal
        title='ADN Lince'
        open={modalADNLinceIsOpen}
        handleClose={() => setModalADNLinceIsOpen(false)}
        handleSubmit={() => setModalADNLinceConfirmacionIsOpen(true)}
        handleSubmitDisabled={() => {
          if (reporteADNLince?.estatus_firma === "firmado") {
            enqueueSnackbar("Proceso de firma realizado", {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
          }
        }}
        customAcceptText='Firma'
        disableAccept={reporteADNLince?.estatus_firma !== "pendiente"}
        onDownloadAlternate={async () => {
          try {
            const response = await fetch(PDFURL);
            if (!response.ok) {
              throw new Error("Error al descargar el archivo");
            }
            const blob = await response.blob();
            const blobURL = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = blobURL;
            link.setAttribute("download", "documento.pdf");
            link.click();
            setTimeout(() => {
              window.URL.revokeObjectURL(blobURL);
              link.removeAttribute("href");
            }, 100);
          } catch (error) {
            enqueueSnackbar("Error al descargar el archivo", {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            console.error(error);
          }
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "65vh",
            overflow: "none",
            position: "relative",
          }}
        >
          {modalADNLinceIsLoading && (
            <div
              style={{
                textAlign: "center",
                marginTop: 20,
                position: "absolute",
                left: "calc(50% - 25px)",
              }}
            >
              <CircularProgress size={50} />
            </div>
          )}
          {PDFURL && (
            <iframe
              title='ADN Lince PDF'
              src={`${PDFURL}#toolbar=0&navpanes=0&scrollbar=0`}
              frameBorder='0'
              style={{
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          )}
        </div>
      </NewModal>
      <NewModal
        title='Confirmación'
        open={modalADNLinceConfirmacionIsOpen}
        loading={modalADNLinceConfirmacionIsLoading}
        handleClose={() => setModalADNLinceConfirmacionIsOpen(false)}
        handleSubmit={handleSubmitADNLince}
        noCancel
      >
        <div style={{ padding: "18px" }}>
          <p>
            Confirmo la recepción del reporte diagnóstico de niveles de dominio
            de competencias genéricas, de los estudiantes inscritos en mi grupo,
            obtenidos a través de ADN Lince, comprometiéndome a hacer uso
            responsable de la información y en pro del proceso de enseñanza
            aprendizaje de mis estudiantes.
          </p>
        </div>
      </NewModal>
      <div
        className='context-menu-container'
        style={{
          display: contextMenuOpen ? "block" : "none",
          top: contextMenuPosition.y,
          left: contextMenuPosition.x,
        }}
      >
        {contextMenuMateriaInfo.tipo_boton_accion ===
        "descarga_cargar_documento" ? (
          <>
            <button
              onClick={() => {
                let a = document.createElement("a");
                a.href = contextMenuMateriaInfo.url_archivo;
                a.setAttribute("download", "documento");
                a.click();
              }}
              className='context-menu-button'
            >
              <PermMediaIcon />
              Descargar Documento
            </button>
            <div style={{ display: "flex" }}>
              <button
                disabled={contextMenuMateriaInfo.url_planeacion !== ""}
                onClick={() => {
                  setModalCargaDocumento(true);
                  setUploadType("");
                  console.log(contextMenuMateriaInfo);
                  setMateriaSeleccionada(contextMenuMateriaInfo);
                }}
                className='context-menu-button'
              >
                <PermMediaIcon />
                Cargar Documento
              </button>
              {contextMenuMateriaInfo.url_planeacion !== "" && (
                <button
                  id='context-menu-delete-document-button'
                  style={{
                    backgroundColor: "#f00",
                    color: "#fff",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "10px",
                    padding: 0,
                  }}
                  onClick={handleDeleteDocument}
                >
                  {/* <DeleteIcon /> */}
                  Eliminar Documento
                </button>
              )}
            </div>
          </>
        ) : (
          <button
            onClick={
              contextMenuMateriaInfo.tipo_boton_accion === "syllabus"
                ? () => {
                    context.actions.setState(
                      contextMenuMateriaInfo,
                      contextMenuMateriaInfo.nss
                    );
                    history.push("/details");
                  }
                : contextMenuMateriaInfo.tipo_boton_accion ===
                  "cargar_documento"
                ? contextMenuMateriaInfo.url_archivo === ""
                  ? () => {
                      setModalCargaDocumento(true);
                      console.log(contextMenuMateriaInfo);
                      setMateriaSeleccionada(contextMenuMateriaInfo);
                    }
                  : () => {
                      let a = document.createElement("a");
                      a.href = contextMenuMateriaInfo.url_archivo;
                      a.setAttribute("download", "documento");
                      a.click();
                    }
                : contextMenuMateriaInfo.tipo_boton_accion ===
                  "descargar_documento"
                ? () => {
                    let a = document.createElement("a");
                    a.href = contextMenuMateriaInfo.url_archivo;
                    a.setAttribute("download", "documento");
                    a.click();
                  }
                : () => {
                    context.actions.setState(
                      contextMenuMateriaInfo,
                      contextMenuMateriaInfo.nss
                    );
                    history.push("/details");
                  }
            }
            className='context-menu-button'
          >
            {getButtonContent(
              contextMenuMateriaInfo.tipo_boton_accion,
              contextMenuMateriaInfo.url_archivo
            )}
          </button>
        )}
        <button
          disabled={
            contextMenuMateriaInfo.envio_planeacion_alumnos === "True" ||
            (contextMenuMateriaInfo.tipo_boton_accion === "cargar_documento" &&
              contextMenuMateriaInfo.url_archivo === "") ||
            (contextMenuMateriaInfo.tipo_boton_accion ===
              "descarga_cargar_documento" &&
              contextMenuMateriaInfo.url_planeacion === "")
          }
          onClick={() => {
            if (contextMenuMateriaInfo.avance === 100) {
              setModalEnvio(true);
              setClave(contextMenuMateriaInfo.id);
            } else {
              enqueueSnackbar(
                "Acción no valida: La planeación debe estar completa",
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                }
              );
            }
          }}
          className='context-menu-button'
        >
          <SchoolIcon />
          {contextMenuMateriaInfo.envio_planeacion_alumnos === "True"
            ? "Planeación enviada a alumnos"
            : "Enviar a Alumnos"}
        </button>
        <button
          onClick={() => handleClickDocumentos(contextMenuMateriaInfo.id)}
          className='context-menu-button'
        >
          <GetAppIcon />
          Documentos
        </button>
        <button
          onClick={() => {
            setNombreMateria(
              contextMenuMateriaInfo.clave +
                " - " +
                contextMenuMateriaInfo.nombre_materia
            );
            handleClickEvidenciasAprendizaje(
              contextMenuMateriaInfo.id_person,
              contextMenuMateriaInfo.id
            );
          }}
          className='context-menu-button'
        >
          <SchoolIcon />
          Evidencias Aprendizaje
        </button>
        <button
          onClick={() =>
            handleOpenModalADNLince(
              contextMenuMateriaInfo.id_person,
              contextMenuMateriaInfo.id
            )
          }
          className='context-menu-button'
        >
          <FingerprintIcon />
          ADN Lince
        </button>
      </div>
    </Contenedor>
  );
}
