import React from "react";
import Modal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import Close from "@material-ui/icons/Close";
import "./newModal.css";

export default function NewModal(props) {
  return (
    <Modal open={props.open}>
      <div
        className={props.syllabus ? "NewModal NewModal-Syllabus" : "NewModal"}
        style={{ width: props.width || null, maxWidth: props.maxWidth }}
      >
        <h3>
          {props.title}
          <Close
            className='close-modal'
            onClick={props.onlyClose || props.handleClose}
          >
            x
          </Close>
        </h3>
        <div className='NewModal-container' style={{ height: props.height }}>
          {props.loading ? (
            <div style={{ textAlign: "center", paddingTop: 30 }}>
              <CircularProgress size={50} />
            </div>
          ) : (
            <>
              {props.children}
              {props.hideFooter ? null : (
                <div className='NewModal-footer'>
                  {props.onDownloadAlternate && (
                    <button
                      onClick={props.onDownloadAlternate}
                      className='download-button'
                    >
                      Descargar Documento
                    </button>
                  )}
                  {props.canDelete ? (
                    <button onClick={props.onDelete} className='delete-button'>
                      Eliminar
                    </button>
                  ) : null}
                  {props.noCancel ? null : (
                    <button className='gray-btn' onClick={props.handleClose}>
                      {props.save
                        ? "Continuar sin guardar"
                        : props.cerrarBtn
                        ? "Cerrar"
                        : "Cancelar"}
                    </button>
                  )}
                  {props.customAcceptText ? (
                    <button className={props.disableAccept ? 'gray-btn' : 'black-btn'} disabled={props.disableAccept && props.handleSubmitDisabled === undefined} onClick={props.disableAccept ? props.handleSubmitDisabled : props.handleSubmit}>
                      {props.customAcceptText}
                    </button>
                  ) : props.onDownload ? (
                    <a href={props.onDownload} target='_blank' download>
                      <button onClick={props.onDownload} className='black-btn'>
                        Descargar
                      </button>
                    </a>
                  ) : props.hideSubmit ? null : (
                    <button className='black-btn' onClick={props.handleSubmit}>
                      {props.save
                        ? "Guardar y Continuar"
                        : props.continue
                        ? "Continuar"
                        : "Aceptar"}
                    </button>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}
